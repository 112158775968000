import {
  squareSVGT,
  circleSVGT,
  distressedsquareSVGT,
  distressedcircleSVGT,
  ruffledcircleSVGT,
  scallopedrectangleSVGT,
  curvedsquareSVGT,
  bookmarkSVGT
} from '../../static/svgtext'

export const ContainerSVGT = distressedcircleSVGT
export const ContainerChoice = "distressedC"
export const MainFont = 'Bahnschrift'
export const SubFont = 'Poppins'

export const HomeColor = "#163654"
export const AboutColor = "#163654"
export const EventsColor = "#163654"
export const LiveColor = "#163654"
export const SermonsColor = "#163654"
export const MinistriesColor = "#0b153c"
export const GivingColor = "#163654"
export const ContactColor = "#163654"
export const ThirdPage = "Livestream (Facebook)"
export const FourthPage = "Sermons"
export const MinistriesName = "Ministries"
export const SixthPage = 'Tithe.ly'